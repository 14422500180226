import React, { useState } from 'react';

import Layout from '../layout';
import config from '../../data/SiteConfig';
import SEO from '../components/SEO/SEO';

import FormSelect from '../components/CustomerConsent/FormSelect';
import SuccessScreen from '../components/CustomerConsent/SuccessScreen';
import FailureScreen from '../components/CustomerConsent/FailureScreen';

const CustomerConsent = () => {
  const [selectedForm, setSelectedForm] = useState('default');
  const [currentStep, setCurrentStep] = useState('');

  return (
    <Layout>
      <div className="customer-consent">
        <SEO metadata={{ title: config.customerConsentTitle }} />
        {!currentStep && (
          <FormSelect
            selectedForm={selectedForm}
            setFormCallback={setSelectedForm}
            setStep={setCurrentStep}
          />
        )}
        {currentStep === 'success' && <SuccessScreen selectedForm={selectedForm} />}
        {currentStep === 'failure' && <FailureScreen />}
      </div>
    </Layout>
  );
};

export default CustomerConsent;
