export const createFormValidations = (messages) => {
  return {
    email: {
      required: messages.formValidations.email.required,
      pattern: {
        value:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: messages.formValidations.email.pattern,
      },
    },
    name: { required: messages.formValidations.name.required },
    referenceNumber: { required: messages.formValidations.referenceNumber.required },
    phoneNumber: { required: messages.formValidations.phoneNumber.required },
    confirmPhoneNumber: { required: messages.formValidations.confirmPhoneNumber.required },
    zipCode: { required: messages.formValidations.zipCode.required },
  };
};
