import React from 'react';

export default (
  <ol>
    <li>
      <h2 className="privacy-title">1. PROPÓSITO</h2>
      <p>
        Spring Oaks Capital, LLC y sus filiales, incluida Spring Oaks Capital SPV, LLC (en conjunto,
        “Spring Oaks”, “nosotros” o “nos”) se comprometen a proteger la privacidad y seguridad de la
        información que recibe por parte de todos los empleados de Spring Oaks (en conjunto,
        “Usuarios”) y proteger la información privada con respecto a sus clientes como prioridad. De
        conformidad con los requisitos de la Ley Gramm-Leach-Bliley (la “GLBA”) y las pautas
        establecidas por la Comisión de Bolsa y Valores (Securities and Exchange Commission, SEC) en
        relación con la Privacidad de la información financiera del consumidor (Reglamentación P),
        esta política (la “Política” o la “Política sobre la Reglamentación P”) y los procedimientos
        relacionados que contiene el presente documento están diseñados para cumplir con las leyes
        de privacidad aplicables, incluidas la GLBA y la Reglamentación P, y para proteger la
        información personal no pública de los clientes. En el caso de que las nuevas leyes o
        reglamentaciones relacionadas con la privacidad que afecten las prácticas de información de
        Spring Oaks sean adoptadas por reguladores federales o estatales, esta Política se revisará
        según sea necesario, y cualquier cambio será difundido y explicado a todo el personal.
      </p>
    </li>
    <li>
      <h2 className="privacy-title">2. ALCANCE</h2>
      <p>
        Esta Política cubre las prácticas de Spring Oaks y se aplica a toda la información de
        identificación personal no pública, incluida la información contenida en los informes del
        cliente, de nuestros clientes actuales y anteriores y de los clientes con los que
        interactuamos. Todas las empresas financieras deben compartir la información personal para
        llevar a cabo sus actividades comerciales diarias. Esta Política enumera los motivos por los
        que las empresas financieras pueden compartir información personal, los motivos por los que
        Spring Oaks decide compartirla y si usted puede limitar esta compartición.
        <br />
        <br />
        El sitio web de Spring Oaks se puede encontrar en springoakscapital.com (su “Sitio web”). El
        Sitio web de Spring Oaks no está destinado a personas menores de dieciocho años, y no
        recopilamos conscientemente datos relacionados con personas menores de dieciocho años. Su
        acceso al Sitio web y uso está condicionado a su aceptación y cumplimiento de esta Política
        de privacidad. Esta Política de privacidad se aplica a todos, incluidos, entre otros, los
        visitantes, usuarios y otros, que deseen acceder al Sitio web o utilizarlo. Al acceder al el
        Sitio web o utilizarlo, usted acepta quedar vinculado por esta Política de privacidad. Si no
        está de acuerdo con alguna parte de la Política de privacidad, no tiene nuestro permiso para
        acceder al Sitio web ni para utilizarlo.
        <br />
        <br />
        Nada de lo contenido en este Aviso de privacidad pretende contradecir sus derechos en virtud
        de la Ley de Prácticas Justas de Cobro de Deudas (Fair Debt Collection Practices Act,
        FDCPA). Spring Oaks no divulgará ninguna información a terceros que esté prohibida por la
        FDCPA.
      </p>
    </li>
    <li>
      <h2 className="privacy-title">3. POLÍTICA</h2>
      <p>
        Spring Oaks y sus empleados reconocen un compromiso continuo con la privacidad de sus
        clientes. Se espera que todos los empleados lean, comprendan y respeten esta política, así
        como que sigan todos los procedimientos relacionados y respeten los estándares de privacidad
        y seguridad establecidos por Spring Oaks.
      </p>
    </li>

    <li>
      <h2 className="privacy-title">4. PROCEDIMIENTOS</h2>
      <ol className="mt-0">
        <li>
          <b>4.1</b> En la Reglamentación P, la SEC publicó pautas, de conformidad con la sección
          501(b) de la GLBA, que abordan las medidas que debe tomar una institución financiera para
          proteger la información del cliente. Los estándares de seguridad generales que deben
          cumplirse son:
          <ol>
            <li>
              <b>4.1.1</b> garantizar la seguridad y confidencialidad de los registros e información
              del cliente;
            </li>
            <li>
              <b>4.1.2</b> proteger contra cualquier amenaza o peligro anticipados para la seguridad
              o integridad de los registros e información del cliente, y
            </li>
            <li>
              <b>4.1.3</b> proteger contra el acceso a o uso no autorizados de registros o
              información del cliente que podrían resultar en daños o inconvenientes sustanciales
              para cualquier cliente.
            </li>
          </ol>
        </li>
        <li>
          <div className="privacy-subtitle">4.2 Responsabilidad</div>
          <ol>
            <li>
              <b>4.2.1</b> Cada Empleado tiene el deber de proteger la información personal no
              pública que recopilamos de los clientes.
            </li>
            <li>
              <b>4.2.2</b> Cada Empleado tiene el deber de garantizar que la información personal no
              pública de los clientes se comparta solo con los empleados y otros de una manera que
              sea coherente con el Aviso de privacidad de Spring Oaks y los procedimientos
              contenidos en esta Política.
            </li>
            <li>
              <b>4.2.3</b> Cada Empleado tiene el deber de garantizar que el acceso a la información
              personal no pública de los clientes sea limitado según lo dispuesto en el Aviso de
              privacidad y esta Política.
            </li>
            <li>
              <b>4.2.4</b> Ningún empleado está autorizado a vender, en nombre de Spring Oaks o de
              otro modo, información no pública de los clientes o permisos para recibir campañas por
              mensajes de texto (SMS) de los clientes.
            </li>
            <li>
              <b>4.2.5</b> Los Empleados que tengan preguntas sobre la recopilación y compartición
              de, o el acceso a, información personal no pública de los clientes deben recurrir al
              director comercial (chief commercial officer, CCO) de Spring Oaks para obtener
              orientación.
            </li>
            <li>
              <b>4.2.6</b> Las infracciones de estas políticas y procedimientos se abordarán de
              forma coherente con otras pautas disciplinarias.
            </li>
          </ol>
        </li>
        <li>
          <div className="privacy-subtitle">4.3 Prácticas de información</div>
          <ol>
            <li>
              <b>4.3.1</b> Spring Oaks limita el uso, la recopilación y la conservación de
              información del cliente a lo que creemos necesario o útil para llevar a cabo nuestro
              negocio u ofrecer productos y servicios de calidad. Spring Oaks recopila información
              personal no pública sobre los clientes de diversas fuentes. Estas fuentes y ejemplos
              de tipos de información recopilada incluyen:
              <ol>
                <li>
                  <b>4.3.1.1</b> Nombre, dirección, número de teléfono, número de Seguro Social o
                  número de identificación fiscal, fecha de nacimiento, situación laboral, ingresos
                  anuales y patrimonio neto.
                </li>
                <li>
                  <b>4.3.1.2</b> Información sobre transacciones con nosotros y el(los) custodio(s)
                  de la cuenta, como el saldo de la cuenta, tipos de transacciones, partes de las
                  transacciones.
                </li>
                <li>
                  <b>4.3.1.3</b> Información recibida de agencias de informes de crédito del
                  cliente, como informes de crédito, propietarios anteriores de la cuenta, agencias
                  gubernamentales y otras compañías.
                </li>
              </ol>
              <table className="table-container">
                <thead>
                  <th>
                    <b>Motivos por los que podemos compartir su información personal</b>
                  </th>
                  <th>
                    <b>¿Comparte Spring Oaks?</b>
                  </th>
                  <th>
                    <b>¿Puede limitar esta compartición?</b>
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b>Para nuestros fines comerciales cotidianos:</b> como procesar sus
                      transacciones, mantener su(s) cuenta(s), responder a órdenes judiciales e
                      investigaciones legales, o informar a agencias de información crediticia
                    </td>
                    <td>Sí</td>
                    <td>No</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Para nuestros fines de marketing: </b>
                      ofrecerle nuestros productos y servicios
                    </td>
                    <td>No</td>
                    <td>No</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Para el marketing conjunto con otras empresas financieras</b>
                    </td>
                    <td>No</td>
                    <td>No compartimos</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Para los fines comerciales cotidianos de nuestras filiales: </b>
                      como información sobre sus transacciones y experiencias
                    </td>
                    <td>Sí</td>
                    <td>No</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Para los fines comerciales cotidianos de nuestras filiales: </b>
                      como información sobre su solvencia
                    </td>
                    <td>Sí</td>
                    <td>No</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Para que las empresas no filiales le comercialicen</b>
                    </td>
                    <td>No</td>
                    <td>No compartimos</td>
                  </tr>
                </tbody>
              </table>
              <p>
                <b>Definiciones:</b>
                <br />
                <br />
                <b>
                  <u>Las Filiales</u>
                </b>{' '}
                son empresas relacionadas por propiedad o control común. Pueden ser empresas
                financieras y no financieras.
                <br />
                <br />
                <b>
                  <u>Las empresas no filiales</u>
                </b>{' '}
                son empresas no relacionadas por propiedad o control común. Pueden ser empresas
                financieras y no financieras.
                <br />
                <br />
                <b>
                  <u>El marketing conjunto</u>
                </b>{' '}
                es un acuerdo formal entre empresas financieras no filiales que juntas le
                comercializan productos o servicios financieros. Spring Oaks no comercializa
                conjuntamente.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <div className="privacy-subtitle">
            4.4 Divulgación de información a terceros no afiliados
          </div>
          Spring Oaks no divulga información personal no pública a terceros no afiliados, excepto
          bajo una de las excepciones de privacidad de la GLBA, como se describe a continuación.
          <ol>
            <li>
              <b>4.4.1</b> Tipos de divulgaciones permitidas: las excepciones
              <ol>
                <li>
                  <b>4.4.1.1</b> En determinadas circunstancias, la Reglamentación P permite a
                  Spring Oaks compartir información personal no pública sobre sus clientes con
                  terceros no afiliados sin proporcionar una oportunidad para que esas personas se
                  excluyan. Estas circunstancias incluyen compartir información con una entidad no
                  afiliada:
                  <ol>
                    <li>
                      <b>4.4.1.1.1</b> según sea necesario para efectuar, administrar o hacer
                      cumplir una transacción que un cliente solicita o autoriza;
                    </li>
                    <li>
                      <b>4.4.1.1.2</b> en relación con el procesamiento o la administración de una
                      cuenta de cobro o un servicio que un cliente autoriza, y
                    </li>
                    <li>
                      <b>4.4.1.1.3</b> en relación con el mantenimiento o la administración de una
                      cuenta de cobro con Spring Oaks.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <div className="privacy-subtitle">4.5 Proveedores de servicios</div>
          <ol>
            <li>
              <b>4.5.1</b> Ocasionalmente, podemos tener relaciones con terceros no afiliados (como
              abogados, auditores, custodios y otros consultores), que, en el curso ordinario de la
              prestación de sus servicios, pueden requerir acceso a información que contiene
              información no pública. Estos proveedores de servicios externos son necesarios para
              que podamos proporcionar nuestros servicios de cobro.
            </li>
            <br />
            <li>
              <b>4.5.2</b> Cuando no estamos seguros de que los proveedores de servicios (p. ej.,
              abogados, auditores y otras instituciones financieras) ya estén obligados por
              obligaciones de confidencialidad, exigimos garantías de esos proveedores de servicios
              de que mantendrán la confidencialidad de la información no pública que obtengan de
              nosotros o a través de nosotros.
            </li>
            <br />
            <li>
              <b>4.5.3</b> Además, seleccionamos y contratamos proveedores de servicios que creemos
              que son capaces de mantener las salvaguardas adecuadas para la información no pública,
              y exigiremos acuerdos contractuales de nuestros proveedores de servicios que
              establecen que implementarán y mantendrán dichas salvaguardas.
            </li>
          </ol>
        </li>
        <li>
          <div className="privacy-subtitle">
            4.6 Procesamiento y administración de transacciones
          </div>
          <ol>
            <li>
              <b>4.6.1</b> También podemos compartir información cuando sea necesario para efectuar,
              administrar o hacer cumplir una transacción solicitada o autorizada por nuestros
              clientes.
              <ol>
                <li>
                  <b>4.6.1.1</b> En este contexto, “necesario para efectuar, administrar o hacer
                  cumplir una transacción”: incluye lo que se requiere o es un método habitual,
                  apropiado o aceptable para llevar a cabo la transacción o el servicio del que
                  forma parte la transacción, y registrar, administrar o mantener la cuenta del
                  cliente en el curso ordinario de la prestación de servicios de cobro.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <div className="privacy-subtitle">4.7 Compartición según lo permita o exija la ley</div>
          <ol>
            <li>
              <b>4.7.1</b> Spring Oaks puede divulgar información a terceros no afiliados según lo
              requiera o permita la ley.
              <ol>
                <li>
                  <b>4.7.1.1</b> Por ejemplo, esto puede incluir divulgaciones en relación con una
                  citación o un proceso legal similar, una investigación de fraude, una auditoría o
                  un examen.
                </li>
              </ol>
            </li>
            <br />
            <li>
              <b>4.7.2</b> Al comprender cómo compartimos los datos con nuestros clientes, sus
              agentes, proveedores de servicios, partes relacionadas en transacciones en el curso
              ordinario de los negocios o comercializadores conjuntos, nos esforzamos por garantizar
              que los datos del cliente se compartan solo conforme a las excepciones mencionadas
              anteriormente.
            </li>
          </ol>
        </li>
        <li>
          <div className="privacy-subtitle">4.8 Aviso de privacidad</div>
          <ol>
            <li>
              <b>4.8.1</b> Spring Oaks enviará un Aviso de privacidad en virtud de la Reglamentación
              P sobre las cuentas que posee de conformidad con esta Política.
            </li>
          </ol>
        </li>
      </ol>
    </li>
    <li>
      <h2 className="privacy-title">5. INFORMACIÓN QUE RECOPILAMOS SOBRE USTED</h2>
      <ol>
        <li>
          <div className="privacy-subtitle">5.1 Información personal</div>
          <ol>
            <li>
              <b>5.1.1</b> Podemos recopilar y utilizar la siguiente información personal que
              identifique, se relacione con, describa, haga referencia a, sea capaz de asociarse o
              pueda vincularse razonablemente con, directa o indirectamente, un cliente, dispositivo
              u hogar (“información personal”). La inclusión de una categoría indica que podemos
              recopilar cierta información en esa categoría. No significa que recopilemos toda la
              información enumerada en esa categoría en todas las situaciones. Podemos haber
              recopilado las siguientes categorías de información personal de los clientes en los
              últimos 12 meses:
            </li>
            <div className="table-container">
              <table>
                <thead>
                  <th>
                    <b>Categorías de información personal</b>
                  </th>
                  <th>
                    <b>Ejemplos de tipos específicos de información personal recopilada</b>
                  </th>
                  <th>
                    <b>Recopilada</b>
                  </th>
                  <th>
                    <b>Propósito</b>
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td>Identificadores</td>
                    <td>
                      Un nombre real, alias, dirección postal, dirección de correo electrónico,
                      números de teléfono, dirección de protocolo de Internet, número de cuenta,
                      número de Seguro Social, fecha de nacimiento u otros identificadores
                      similares.
                    </td>
                    <td>SÍ</td>
                    <td>
                      (a) Necesario para prestar los servicios <br />
                      (b) Necesario para cumplir con una obligación legal <br />
                      (c) Necesario para nuestros intereses comerciales legítimos <br />
                      (d) Evitar el fraude
                    </td>
                  </tr>
                  <tr>
                    <td>Categorías de Información personal</td>
                    <td>
                      Nombre, firma, número de Seguro Social, características físicas o descripción,
                      dirección, número de teléfono, número de pasaporte, número de tarjeta de
                      identificación estatal o de licencia de conducir, educación, empleo, historial
                      laboral, número de cuenta bancaria, número de tarjeta de crédito, número de
                      tarjeta de débito o cualquier otra información financiera. Parte de la
                      información personal incluida en esta categoría puede superponerse con otras
                      categorías.
                    </td>
                    <td>SÍ</td>
                    <td>
                      (a) Necesario para prestar los servicios <br />
                      (b) Necesario para cumplir con una obligación legal <br />
                      (c) Necesario para nuestros intereses comerciales legítimos <br />
                      (d) Evitar el fraude
                    </td>
                  </tr>
                  <tr>
                    <td>Características de clasificación protegidas</td>
                    <td>Edad (40 años o más), condición de veterano o militar</td>
                    <td>SÍ</td>
                    <td>
                      (a) Necesario para prestar los servicios <br />
                      (b) Necesario para cumplir con una obligación legal <br />
                      (c) Necesario para nuestros intereses comerciales legítimos
                    </td>
                  </tr>
                  <tr>
                    <td>Información comercial</td>
                    <td>
                      Registros de bienes personales, productos o servicios adquiridos, obtenidos o
                      considerados, u otros historiales o tendencias de compra o consumo
                    </td>
                    <td>SÍ</td>
                    <td>
                      (a) Necesario para prestar los servicios <br />
                      (b) Necesario para cumplir con una obligación legal <br />
                      (c) Necesario para nuestros intereses comerciales legítimos
                    </td>
                  </tr>
                  <tr>
                    <td>Información profesional o relacionada con el empleo</td>
                    <td>Historial laboral actual o pasado</td>
                    <td>SÍ</td>
                    <td>
                      (a) Necesario para prestar los servicios <br />
                      (b) Necesario para cumplir con una obligación legal <br />
                      (c) Necesario para nuestros intereses comerciales legítimos
                    </td>
                  </tr>
                  <tr>
                    <td>Datos sensoriales</td>
                    <td>Audio, electrónico, visual</td>
                    <td>SÍ</td>
                    <td>
                      {' '}
                      (a) Necesario para prestar los servicios <br />
                      (b) Necesario para cumplir con una obligación legal <br />
                      (c) Necesario para nuestros intereses comerciales legítimos
                    </td>
                  </tr>
                  <tr>
                    <td>Información personal confidencial</td>
                    <td>
                      Números de identificación emitidos por el gobierno, como licencia de conducir,
                      pasaporte o número de la seguridad social, detalles de cuentas financieras que
                      permiten el acceso a una cuenta, como un número de tarjeta de crédito y código
                      de acceso, contenido de correo, correo electrónico, chat o mensajes de texto.
                    </td>
                    <td>
                      SÍ <br />
                      <br />
                      No se recopila ni procesa Información personal confidencial con el fin de
                      deducir características sobre un cliente.
                    </td>
                    <td>
                      (a) Necesario para prestar los servicios <br />
                      (b) Mantener y dar servicio a su cuenta <br />
                      (c) Procesar pagos <br />
                      (d) Garantizar la seguridad e integridad en relación con el uso de dicha
                      información personal <br />
                      (e) Verificar o mantener la seguridad y calidad de los servicios <br />
                      (f) Necesario para cumplir con una obligación legal <br />
                      (g) Necesario para nuestros intereses comerciales legítimos
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <li>
              <b>5.1.2</b> La información personal no incluye:
              <ul>
                <li>Información disponible públicamente de registros gubernamentales.</li>
                <li>Información de clientes anonimizada o agregada.</li>
                <li>Excluye tipos de información, como:</li>
                <li>
                  información médica o de salud cubierta por la Ley de Portabilidad y
                  Responsabilidad del Seguro Médico de 1996 (Health Insurance Portability and
                  Accountability Act, HIPAA) y la Ley de Confidencialidad de la Información Médica
                  de California (California Confidentiality of Medical Information Act, CMIA) o los
                  datos de ensayos clínicos;
                </li>
                <li>
                  información personal cubierta por ciertas leyes de privacidad específicas del
                  sector, incluida la Ley de Equidad de Informes de Crédito (Fair Credit Reporting
                  Act, FCRA), la Ley Gramm-Leach-Bliley (GLBA) o la Ley de Privacidad de la
                  Información Financiera de California (Financial Information Privacy Act, FIPA) y
                  la Ley de Protección de la Privacidad del Conductor de 1994.
                </li>
              </ul>
            </li>
          </ol>
        </li>
        <li>
          <div className="privacy-subtitle">5.2 Cómo recopilamos su información</div>
          <ol>
            <li>
              <b>5.2.1</b> Recopilamos la mayor parte de esta información personal directamente de
              vendedores de carteras de deuda, así como de usted por teléfono, correspondencia
              escrita por correo postal, correo electrónico, SMS, chat o fax, viendo páginas
              públicas de redes sociales u otra información disponible en línea. Sin embargo,
              también podemos recopilar información:
              <ul>
                <li>
                  de fuentes de acceso público (p. ej., registros de propiedades o registros
                  judiciales);
                </li>
                <li>de sus transacciones con nosotros;</li>
                <li>
                  de nuestros proveedores de servicios (p. ej., proveedores de servicios, proveedor
                  de cartas, proveedores de localización, proveedores de procesamiento de pagos,
                  proveedores de análisis de llamadas y/o proveedores de servicios de firma
                  electrónica);
                </li>
                <li>
                  directamente de un tercero (p. ej., terceros contactados durante actividades de
                  localización de conformidad con el Título 15 del Código de Estados Unidos (United
                  States Code, U.S.C.) §1692b, como sus amigos, vecinos, familiares y/o empleador);
                </li>
                <li>
                  agencias de informes de crédito del cliente (consumer reporting agency, CRA);
                </li>
                <li>
                  de un tercero con su consentimiento (p. ej., su representante autorizado y/o
                  abogado), y
                </li>
                <li>de la actividad en nuestro sitio web.</li>
              </ul>
            </li>
          </ol>
        </li>

        <li>
          <div className="privacy-subtitle">5.3 Cómo utilizamos su información</div>
          <ol>
            <li>
              <b>5.3.1</b> La información personal se recopila únicamente con el fin de recuperar
              deudas de una manera legal y sigue siendo parte de nuestros registros hasta que
              determinemos que la información ya no es necesaria, o estamos obligados por ley a
              eliminar dicha información. Recopilaremos la cantidad mínima de datos necesaria para
              cobrar una deuda.
            </li>
            <br />
            <li>
              <b>5.3.2</b> Podemos utilizar su información personal para uno o más de los siguientes
              fines comerciales:
            </li>
            <ul>
              <li>
                Para cumplir o satisfacer el motivo por el que proporcionó la información. Por
                ejemplo, si comparte su información personal para realizar un pago, utilizaremos esa
                información para procesar su pago.
              </li>
              <li>
                Prestar servicios en nombre de la empresa o proveedor de servicios, incluido el
                mantenimiento o servicios de registros, proporcionar servicio al cliente, procesar o
                cumplir pedidos y transacciones, verificar la información del cliente, procesar
                pagos o proporcionar servicios similares en nombre de la empresa o proveedor de
                servicios;
              </li>
              <li>Para proporcionarle la información o servicios que nos solicite;</li>
              <li>
                Para responder a solicitudes de agentes policiales y según lo permita de otra forma
                la ley aplicable, una orden judicial o las reglamentaciones gubernamentales;
              </li>
              <li>
                Para garantizar la seguridad y la integridad en la medida en que la información
                personal sea razonablemente necesaria y proporcional a estos fines;
              </li>
              <li>
                Para verificar o mantener la calidad o seguridad de los servicios o la cuenta, o
                para prevenir el fraude.
              </li>
            </ul>
            <br />
            <li>
              <b>5.3.3</b> La información personal confidencial se recopila solo según sea necesaria
              para prestar nuestros servicios, procesar pagos, mantener o dar servicio a su cuenta,
              garantizar la seguridad e integridad con respecto al uso de dicha información personal
              y verificar o mantener la seguridad y calidad de los Servicios. No se recopila
              información personal confidencial con el fin de deducir características sobre usted.
              No recopilamos información personal “confidencial”, según ese término se define en
              ciertos estados.
              <br />
              <br />
              No recopilaremos categorías adicionales de información personal ni utilizaremos la
              información personal que hayamos recopilado para fines sustancialmente diferentes, no
              relacionados o incompatibles sin notificarle.
            </li>
            <br />
            <li>
              <b>5.3.4</b> No vendemos y no venderemos su información personal, incluido el permiso
              para recibir campañas por mensajes de texto (SMS). Tampoco “compartimos” su permiso
              para recibir campañas por mensajes de texto (SMS) ni su información personal, tal como
              se define ese término en la Ley de Derechos de Privacidad de California.
            </li>
          </ol>
        </li>
        <li>
          <div className="privacy-subtitle">5.4 Con quién compartimos su información personal</div>
          <ol>
            <li>
              <b>5.4.1</b> Podemos compartir la información de identificación personal que
              recopilamos con nuestros empleados y filiales que necesitan conocer esa información
              para administrar su cuenta. Excepto por lo dispuesto a continuación, no compartimos ni
              divulgamos ninguna información de identificación personal a ninguna empresa o grupo de
              marketing externo a nosotros. Podemos compartir su información personal con terceros y
              proveedores de servicios en la medida en que sea razonablemente necesario para
              gestionar o administrar su cuenta, verificar el empleo, determinar la ubicación,
              procesar el pago, realizar una transacción, proporcionar servicio al cliente o según
              lo autorice la ley.
            </li>
            <br />
            <li>
              <b>5.4.2</b> Además, podemos divulgar información de identificación personal (i) a
              otra entidad con la que iniciemos o razonablemente podamos iniciar una transacción
              corporativa, como, por ejemplo, una fusión, consolidación, adquisición, o compra de
              activos, (ii) a un tercero de conformidad con una citación, orden judicial u otra
              forma de proceso legal, o en respuesta a una solicitud por, o en nombre de, cualquier
              agencia, departamento u organismo gubernamental, estatal, federal u otro, ya sea de
              conformidad o no con una citación, orden judicial u otra forma de proceso legal, o en
              relación con litigios iniciados contra, o en nombre de, Spring Oaks, cuando sea
              apropiado, (iii) a un tercero si Spring Oaks determina a su exclusivo criterio que
              dicha divulgación es apropiada para proteger la vida, salud o propiedad de Spring Oaks
              o cualquier otra persona o entidad, en cumplimiento de la legislación aplicable, (iv)
              a terceros según lo autorizado o designado por usted, o (v) para llevar a cabo
              cualquier otra actividad comercial legítima que no esté prohibida por ley. Lo anterior
              no pretende obviar ni sustituir ninguna obligación o responsabilidad legal aplicable a
              Spring Oaks.
            </li>
            <br />
            <li>
              <b>5.4.3</b> Excepto cuando sea necesario para que proporcionemos los servicios, la
              información o los productos solicitados por un usuario del sitio web, o excepto para
              las divulgaciones identificadas en los párrafos anteriores, el usuario puede excluirse
              de que su información de identificación personal, que nos ha sido proporcionada
              voluntariamente a través o desde su sitio web, sea conservada prospectivamente por
              nosotros, utilizada por nosotros para fines secundarios o divulgada por nosotros a
              terceros.
            </li>
            <br />
            <li>
              <b>5.4.4</b> Es posible que el correo electrónico publicado o enviado no sea seguro
              contra la interceptación por parte de personas no autorizadas. Para protegernos contra
              la interceptación por parte de personas no autorizadas, o debido a que no podemos
              verificar su identidad, es posible que no podamos responder a solicitudes de correo
              electrónico relacionadas con cuentas asignadas al cobro, a menos que usted nos haya
              solicitado o autorizado hacerlo.
            </li>
          </ol>
        </li>
        <li>
          <div className="privacy-subtitle">
            5.5 Compartir su información con agencias de informes de crédito del cliente
          </div>
          <ol>
            <li>
              <b>5.5.1</b> Las agencias de informes de crédito del cliente (Consumer Reporting
              Agencies, CRA) recopilan y mantienen información sobre los perfiles de crédito de
              clientes y empresas en nombre de organizaciones en los Estados Unidos. Podemos
              compartir información sobre usted con las CRA y realizar búsquedas periódicas con
              ellas para verificar su identidad o gestionar su cuenta.
            </li>
            <br />
            <li>
              <b>5.5.2</b> Los detalles de su(s) cuenta(s) con nosotros pueden enviarse a las CRA y
              ser registrados por ellas. Esta información puede ser suministrada por las CRA y puede
              ser utilizada y buscada por nosotros y otras organizaciones, como agencias de cobro de
              deudas, con el fin de:
              <ul>
                <li>considerar solicitudes de crédito y servicios relacionados con el crédito;</li>
                <li>localizar deudores y recuperar deudas, y</li>
                <li>gestionar sus cuentas.</li>
              </ul>
            </li>
            <br />
            <li>
              <b>5.5.3</b> Spring Oaks puede proporcionar información de cuenta a Experian, Equifax
              y TransUnion. Tiene derecho a obtener una copia anual de su informe crediticio de las
              CRA visitando www.annualcreditreport.com.
            </li>
          </ol>
        </li>
        <li>
          <div className="privacy-subtitle">5.6 Venta de Información personal</div>
          <p>
            No vendemos y no venderemos su información personal, incluido el permiso para recibir
            campañas por mensajes de texto (SMS). Tampoco “compartimos” su permiso para recibir
            campañas por mensajes de texto (SMS) ni su información personal, tal como se define ese
            término en la Ley de Derechos de Privacidad de California.
          </p>
        </li>
        <li>
          <div className="privacy-subtitle">5.7 Cómo utilizamos las cookies</div>
          <p>
            Spring Oaks puede recopilar cierta información de computadoras y navegadores a través de
            tecnologías automatizadas, como cookies y balizas web, cuando visita nuestro sitio web.
            Las cookies son pequeños fragmentos de información (de texto) que se envían a su
            navegador cuando visita el sitio web para identificar el navegador o para almacenar
            información o la configuración en el navegador. Luego se almacenan en el disco duro o en
            la memoria de su equipo. El navegador puede recuperar esta información en su próxima
            visita al sitio web. Las cookies colocadas a través del sitio web no pueden dañar su
            equipo ni los archivos almacenados en él. Las balizas web (también conocidas como
            etiquetas de Internet, etiquetas de píxel o GIF transparentes) enlazan páginas web a
            servidores web, y sus cookies pueden utilizarse para transmitir la información
            recopilada a un servidor web.
          </p>
        </li>
        <li>
          <div className="privacy-subtitle">5.8 Sus opciones</div>
          <p>
            Spring Oaks le ofrece ciertas opciones en relación con los datos personales que recopila
            de usted, entre ellas:
          </p>
          <p>
            Su cuenta de Spring Oaks: Puede revisar, actualizar y corregir su información de
            contacto realizando una de las siguientes acciones:
            <ul>
              <li>Llámenos al número gratuito 866-281-3065 o</li>
              <li>Envíenos un correo electrónico a springoakscapital.com o</li>
              <li>Escríbanos a P.O. Box 1216, Chesapeake, VA 23327-1216.</li>
            </ul>
            <p>
              Su opción de rechazar las cookies: Puede ajustar la configuración de su navegador para
              rechazar las cookies si no desea aceptar las cookies de Spring Oaks. Sin embargo,
              rechazar las cookies puede afectar al funcionamiento adecuado del sitio web de Spring
              Oaks.
            </p>
            <p>
              Sus pagos: Puede optar por no utilizar el portal de pagos del sitio web de Spring Oaks
              y realizar pagos mediante tarjeta de crédito o cheque
            </p>
            <p>
              Si tiene alguna pregunta con respecto a alguna de estas opciones, comuníquese con
              Spring Oaks.
            </p>
          </p>
        </li>
        <li>
          <div className="privacy-subtitle">
            5.9 Durante cuánto tiempo conservamos su información
          </div>
          <p>
            Spring Oaks conservará sus datos personales hasta que determinemos que la información ya
            no es necesaria, o según lo exija la ley.
          </p>
        </li>
        <li>
          <div className="privacy-subtitle">5.10 Sus opciones:</div>
          <ol>
            <li>
              <b>5.10.1</b> Solicitud de acceso a la información personal: puede enviar una
              solicitud verificable de acceso a la información personal que hayamos recopilado sobre
              usted, sujeta a ciertas excepciones. Esto puede incluir las categorías y partes
              específicas de la información recopilada, las fuentes de esa información y el
              propósito comercial o empresarial para la recopilación o divulgación de esa
              información.
            </li>
            <br />
            <li>
              <b>5.10.2</b> Solicitar la corrección de los datos personales que conservamos sobre
              usted. Esto le permite corregir cualquier dato incompleto o inexacto que tengamos
              acerca de usted, aunque es posible que tengamos que verificar la exactitud de los
              nuevos datos que nos proporcione.
            </li>
            <br />
            <li>
              <b>5.10.3</b> Solicitud de eliminación de información personal: puede solicitar que
              eliminemos su información personal; sin embargo, las leyes estatales y federales
              pueden prohibirnos eliminar información personal, en tal caso, Spring Oaks se lo
              informará. También puede solicitarnos que eliminemos o extraigamos sus datos
              personales cuando haya ejercido con éxito su derecho a oponerse al tratamiento (véase
              a continuación), cuando hayamos tratado su información de forma ilegal o cuando
              estemos obligados a eliminar sus datos personales para cumplir con la legislación
              local. Podemos denegar su solicitud de eliminación si es necesario conservar la
              información para que nosotros o nuestros proveedores de servicios hagamos lo
              siguiente:
              <ul>
                <li>
                  Completar la transacción para la que se recopiló la información personal,
                  proporcionar un bien o servicio solicitado por usted, o razonablemente anticipado
                  dentro del contexto de nuestra relación comercial continua con usted, o de otro
                  modo ejecutar un contrato entre usted y nosotros.
                </li>
                <li>
                  Detectar incidentes de seguridad, proteger contra actividades maliciosas,
                  engañosas, fraudulentas o ilegales, o enjuiciar a aquellos responsables de estas
                  actividades.
                </li>
                <li>
                  Eliminar fallos para identificar y reparar errores que afecten la funcionalidad
                  prevista existente.
                </li>
                <li>
                  Permitir únicamente usos internos que estén razonablemente alineados con sus
                  expectativas en función de su relación con nosotros.
                </li>
                <li>Cumplir con una obligación legal existente.</li>
                <li>
                  Utilizar su información personal, internamente, de una manera legal que sea
                  compatible con el contexto en el que proporcionó la información.
                </li>
              </ul>
            </li>
            <br />
            <li>
              <b>5.10.4</b> Objetar el tratamiento de sus datos personales: cuando nos basemos en un
              interés legítimo (o los de un tercero) y haya algo sobre su situación particular que
              le haga oponerse al tratamiento por este motivo, ya que siente que afecta sus derechos
              y libertades fundamentales, puede oponerse al tratamiento de dichos datos. También
              puede oponerse cuando tratemos sus datos personales con fines de marketing directo. En
              algunos casos, podemos demostrar que tenemos motivos legítimos imperiosos para tratar
              su información que prevalecen sobre sus derechos y libertades.
            </li>
            <br />
            <li>
              <b>5.10.5</b> Solicitar la restricción del tratamiento de sus datos personales: esto
              le permite solicitarnos que suspendamos el tratamiento de sus datos personales en las
              siguientes situaciones: (a) si desea que establezcamos la exactitud de los datos; (b)
              cuando nuestro uso de los datos sea ilegal, pero usted no desee que los borremos; (c)
              cuando necesite que conservemos los datos, aunque ya no los necesitemos, ya que los
              necesita para establecer, ejercer o defender reclamaciones legales, o (d) usted se
              haya opuesto a nuestro uso de sus datos, pero necesitamos verificar si tenemos motivos
              legítimos predominantes para usarlos.
            </li>
            <br />
            <li>
              <b>5.10.6</b> Solicitar la transferencia de sus datos personales a usted o a un
              tercero: le proporcionaremos a usted, o a un tercero que usted haya elegido, sus datos
              personales en un formato estructurado, de uso común y legible por máquina. Tenga en
              cuenta que esto solo se aplica a la información automatizada para cuyo uso usted nos
              proporcionó inicialmente su consentimiento o cuando la usemos para ejecutar un
              contrato con usted.
            </li>
            <br />
            <li>
              <b>5.10.7</b> Retirar el consentimiento en cualquier momento cuando nos basemos en el
              consentimiento para tratar sus datos personales; sin embargo, esto no afectará la
              legalidad de ningún tratamiento realizado antes de que usted retire su consentimiento.
              Si retira su consentimiento, es posible que no podamos proporcionarle ciertos
              productos o servicios. Le informaremos si este es el caso en el momento en que retire
              su consentimiento.
            </li>
            <br />
            <li>
              <b>5.10.8</b> Toma de decisiones automatizada y elaboración de perfiles: “Toma de
              decisiones automatizada” se refiere a decisiones de crédito relativas a los términos o
              condiciones de préstamos al cliente basados únicamente en el tratamiento automatizado
              de los datos personales por parte del acreedor. Esto significa que las decisiones de
              crédito pueden ser tomadas por un acreedor, por ejemplo, utilizando un código de
              software o un algoritmo que no requiera intervención humana. No otorgamos crédito ni
              tomamos decisiones de crédito para ninguna persona y no utilizamos sus datos
              personales como parte de dicha toma de decisiones automatizada o elaboración de
              perfiles al administrar su cuenta.
            </li>
            <br />
            <li>
              <b>5.10.9</b> No discriminación: no le discriminaremos si ejerce alguna de estas
              opciones.
            </li>
            <br />
            <li>
              <b>5.10.10</b> Agente autorizado: puede designar a un agente autorizado para que
              realice cualquiera de estas solicitudes proporcionando su autorización expresa por
              escrito. Debemos poder verificar su identidad, y la autorización debe incluir el
              nombre, la dirección, el número de teléfono y la dirección de correo electrónico del
              agente autorizado (para enviar la información personal recopilada o para responder a
              una solicitud de eliminación).
            </li>
            <br />
            <li>
              <b>5.10.11</b> Si desea ejercer alguna de estas opciones, comuníquese con Spring Oaks
              mediante una de las siguientes acciones:
              <ul>
                <li>Llámenos al número gratuito 866-281-3065 o</li>
                <li>Envíenos un correo electrónico a springoakscapital.com o</li>
                <li>Escríbanos a P.O. Box 1216, Chesapeake, VA 23327-1216.</li>
              </ul>
              <p>
                Si decide comunicarse directamente por teléfono o por escrito, deberá
                proporcionarnos:
              </p>
              <ul>
                <li>
                  información suficiente para que podamos identificarle (p. ej., su nombre completo,
                  dirección y número de referencia del cliente o del asunto);
                </li>
                <li>
                  comprobante de su identidad y dirección (p. ej., una copia de su licencia de
                  conducir o pasaporte y una factura reciente de servicios públicos o tarjeta de
                  crédito), y
                </li>
                <li>
                  una descripción de su solicitud con suficientes detalles que nos permitan
                  comprenderla, evaluarla y responderla correctamente.
                </li>
              </ul>
            </li>
            <br />
            <li>
              <b>5.10.12</b> No estamos obligados a realizar una divulgación de acceso a datos o
              portabilidad de datos si no podemos verificar que la persona que realiza la solicitud
              es la persona sobre la que recopilamos información, o es alguien autorizado a actuar
              en nombre de dicha persona. Para evitar que alguien que no sea usted, o su agente
              autorizado, ejerza las opciones correctas para conocer o el derecho a eliminar con
              respecto a su información personal, seguimos procedimientos para verificar su
              identidad o la de su agente. Estos procedimientos pretenden confirmar que la persona
              que realiza una solicitud es la persona sobre la que hemos recopilado información
              personal o el agente autorizado de esa persona. Los procedimientos de verificación
              implican hacer coincidir los puntos de datos que usted proporciona con su solicitud
              con la información sobre usted que ya tenemos en nuestros registros y que hemos
              determinado que es confiable a los fines de verificar su identidad. Utilizaremos la
              información que nos proporcione en su formulario de solicitud completado para
              verificar su identidad, y podremos solicitar información adicional si es necesario
              para completar el proceso de verificación.
              <br />
              <br />
              No tendrá que pagar una tarifa para acceder a sus datos personales (ni para ejercer
              cualquiera de las otras opciones). Sin embargo, podemos cobrar una tarifa razonable si
              su solicitud es claramente infundada, repetitiva o excesiva. Como alternativa, podemos
              negarnos a cumplir con su solicitud en estas circunstancias.
              <br />
              <br />
              Si desea apelar nuestra decisión con respecto al ejercicio de las opciones anteriores,
              se puede enviar una apelación al responsable del tratamiento de datos utilizando el
              mismo proceso para enviar las solicitudes iniciales. La respuesta a una apelación
              puede demorar hasta 60 días, momento en el cual Spring Oaks responderá a su solicitud.
            </li>
          </ol>
        </li>
        <br />
        <li>
          <b>5.11</b> Tiempo y formato de respuesta
          <p>
            Nos esforzamos por responder a una solicitud de cliente verificable en un plazo de 45
            días a partir de su recepción. Si necesitamos más tiempo (hasta 90 días), le
            informaremos el motivo y período de extensión por escrito. Si tiene una cuenta con
            nosotros, le enviaremos nuestra respuesta por escrito a esa cuenta. Si no tiene una
            cuenta con nosotros, le enviaremos nuestra respuesta por escrito por correo postal o
            electrónicamente, a su elección. Cualquier divulgación que proporcionemos solo cubrirá
            el período de 12 meses anterior a la recepción de la solicitud de cliente verificable.
            La respuesta que proporcionemos también explicará los motivos por los que no podemos
            cumplir con una solicitud, si corresponde. Para las solicitudes de portabilidad de
            datos, seleccionaremos un formato para proporcionar su información personal que sea
            fácilmente utilizable y que le permita transmitir la información de una entidad a otra
            sin obstáculos.
          </p>
        </li>
      </ol>
    </li>
    <li>
      <h2 className="privacy-title">6. CÓMO PROTEGEMOS LA INFORMACIÓN</h2>
      <p>
        Spring Oaks ha implementado medidas de seguridad físicas, electrónicas y procedimentales
        para proteger contra la divulgación o el acceso no autorizados a la información personal.
        Empleamos salvaguardas de sistemas internos y externos diseñadas para proteger la
        confidencialidad y seguridad de la información personal. La confidencialidad de cualquier
        comunicación o material transmitido hacia o desde Spring Oaks a través del sitio web o por
        correo electrónico no puede garantizarse ni está garantizada. Usted reconoce que el
        procesamiento técnico y la transmisión del contenido del sitio web pueden transferirse sin
        cifrar e implican: (a) transmisiones a través de varias redes, y (b) cambios para confirmar
        y adaptarse a los requisitos técnicos de las redes o los dispositivos de conexión. Si surge
        alguna pregunta sobre la seguridad, comuníquese con nosotros utilizando la información
        proporcionada anteriormente.
      </p>
    </li>
    <li>
      <h2 className="privacy-title">7. ENLACES A OTROS SITIOS WEB</h2>
      <p>
        Nuestro sitio web puede contener enlaces para permitirle visitar otros sitios web de interés
        fácilmente. Sin embargo, una vez que haya utilizado estos enlaces para salir de nuestro
        sitio, debe tener en cuenta que no tenemos ningún control sobre ese otro sitio web. Por lo
        tanto, no podemos ser responsables de la protección y privacidad de cualquier información
        que proporcione mientras visita dichos sitios y dichos sitios no se rigen por esta
        declaración de privacidad. Debe tener cuidado y consultar la declaración de privacidad
        aplicable al sitio web en cuestión.
      </p>
    </li>
    <li>
      <h2 className="privacy-title">8. CAMBIOS EN EL AVISO DE PRIVACIDAD</h2>
      <p>
        Spring Oaks puede cambiar este Aviso de privacidad en cualquier momento. El aviso de
        cualquier Aviso de privacidad nuevo o modificado, así como la ubicación de la declaración
        nueva o modificada, se publicará en el sitio web después del cambio. Es obligación de los
        usuarios que visiten el sitio web antes del cambio conocer los cambios en el Aviso de
        privacidad desde su última visita.
        <br />
        <br />
        Si hay cambios sustanciales en la declaración de privacidad o en la forma en que
        utilizaremos sus datos personales, le notificaremos mediante una publicación destacada de un
        aviso de dichos cambios antes de que entren en vigor o enviándole directamente una
        notificación.
      </p>
    </li>
    <li>
      <h2 className="privacy-title">9. PROCEDIMIENTOS DE AUDITORÍA</h2>
      <p>
        Spring Oaks monitoreará periódicamente los sistemas electrónicos como el correo electrónico
        y el Internet. Cualquier infracción directa, indirecta o intento de infracción, de esta
        Política, por parte de o en nombre de un Usuario, y cualquier infracción real o intento de
        infracción por parte de un Tercero en nombre de un Usuario, se considerará una infracción de
        la Política por parte del Usuario, y el Usuario será considerado directamente responsable.
        En el caso de que tengamos conocimiento de que alguna actividad del Usuario puede haber
        infringido esta Política y/o habernos expuesto a responsabilidad civil o penal, Spring Oaks
        se reserva el derecho de investigar dicha actividad, monitorear, recopilar pruebas y
        bloquear el acceso a dicho material y cooperar con las autoridades legales y terceros en la
        investigación de cualquier presunta infracción de esta Política. También nos reservamos el
        derecho de implementar mecanismos técnicos para evitar infracciones de políticas, incluido
        el monitoreo electrónico de sistemas como el correo electrónico y el Internet. Los usuarios
        que infrinjan esta Política o cualquier otra política o norma publicada por Spring Oaks
        podrían estar sujetos a medidas disciplinarias por parte de nosotros, que pueden incluir el
        despido inmediato. Además, las conductas que sean ilegales en virtud de las leyes aplicables
        pueden someter a los Usuarios al enjuiciamiento civil y, en algunos casos, penal.
      </p>
    </li>

    <li>
      <h2 className="privacy-title">10. FRECUENCIA DE CAPACITACIÓN Y PRUEBAS</h2>
      <p>
        Spring Oaks es el responsable del tratamiento de sus datos personales. Todo el personal de
        Spring Oaks recibirá capacitación sobre las políticas de nuestra empresa aplicables a su
        puesto tras la contratación o el ascenso. El mismo personal de la empresa realizará las
        certificaciones de políticas anualmente.
        <br />
        <br />
        Las preguntas o los comentarios sobre nuestras prácticas de datos pueden enviarse a:
        <br />
        <br />
        Spring Oaks Capital, LLC
        <br />
        P.O. Box 1216
        <br />
        Chesapeake, VA 23327-1216
        <br />
        <br />O bien, llamándonos al número gratuito 866-281-3065.
      </p>
    </li>
  </ol>
);
