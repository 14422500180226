import React, { useMemo } from 'react';
import parse from 'html-react-parser';

import Button from '../common/Button/Button';

import headerIllustrationSrc from '../../assets/home/header-illustration.png';
import spanishHeaderIllustrationSrc from '../../assets/home/spanish-header-illustration.png';
import useAnalytics from '../../hooks/useAnalytics';

import { getLocale, getMessages } from '../../locales';
import { getPortalUrl } from '../../utils';

const Header = () => {
  const { trackEvent } = useAnalytics();

  const messages = getMessages();
  const locale = getLocale();

  const headerIllustration = locale === 'en' ? headerIllustrationSrc : spanishHeaderIllustrationSrc;

  return (
    <section className="home-header">
      <div className="container">
        <div className="row no-gutters align-items-center">
          <div className="content col-sm-12 col-md-12 col-lg-6">
            <h1 className="page-title">{parse(messages['header.title'])}</h1>
            <p className="subtitle">{parse(messages['header.description'])}</p>
            <Button
              buttonStyle="redesigned-primary"
              title={messages['header.viewAccount']}
              linkTo="#"
              onClick={() => {
                trackEvent('Click View my Account', { Location: 'header' });
                const portalUrl = getPortalUrl();
                location.href = portalUrl + `?lang=${locale}`;
              }}
              ariaLabel={messages['header.viewAccount']}
            />
          </div>
          <div className="offset-lg-6" />
        </div>
        <div className="home-header-image">
          <img src={headerIllustration} alt={messages['header.altImage']} />
        </div>
      </div>
    </section>
  );
};

export default Header;
