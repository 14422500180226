import React from 'react';
import Button from '../common/Button/Button';

import { getMessages } from '../../locales';
import aboutUsImage from '../../assets/about/header-image.svg';

import parse from 'html-react-parser';

const AboutHeader = () => {
  const messages = getMessages();

  return (
    <section className="about-header">
      <div className="container about-header-intro">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <h1 className="page-title">{messages['about.header.title']}</h1>
            <p className="subtitle">{parse(messages['about.header.desc'])}</p>
          </div>
          <div className="col-lg-6 col-md-12">
            <img src={aboutUsImage} alt={messages['about.header.imageAlt']} />
          </div>
        </div>
      </div>
      <div className="about-header-image-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <h2 className="title-lg">{parse(messages['about.card.title'])}</h2>
            </div>
            <div className="col-lg-6 col-md-12 position-relative">
              <p className="subtitle">{parse(messages['about.card.desc'])}</p>
              <Button
                buttonStyle="redesigned-primary"
                className="position-absolute"
                linkTo="/contact-us"
                title={messages['about.card.button']}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutHeader;
