export const createContactUsValidations = (messages) => {
  return {
    email: {
      required: messages.contactUsValidations.email.required,
      pattern: {
        value:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: messages.contactUsValidations.email.pattern,
      },
    },
    query: {
      required: messages.contactUsValidations.query.required,
    },
  };
};
