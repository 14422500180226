import React from 'react';

import { getMessages } from '../../locales';

import optInSrc from '../../assets/customer-consent/opt-in-success.svg';
import optOutSrc from '../../assets/customer-consent/opt-out-success.svg';

import parse from 'html-react-parser';
import Button from '../common/Button/Button';

const SuccessScreen = ({ selectedForm }) => {
  const messages = getMessages();
  const successImage = selectedForm === 'subscribe' ? optInSrc : optOutSrc;

  return (
    <div className="container">
      <div className="row no-gutters align-items-center">
        <div className="hide-md-down col-lg-5">
          <img src={successImage} alt={messages['optForm.success'][selectedForm].title} />
        </div>
        <div className="col-lg-7 col-12">
          <h2 className="title-lg">{messages['optForm.success'][selectedForm].title}</h2>
          <p className="box-subtitle">{messages['optForm.success'][selectedForm].description}</p>
          <p className="disclaimer-title">
            <b>{messages['contactUs.disclaimer']}</b>
          </p>
          <div className="row no-gutters disclaimers">
            <div className="col-lg-6 col-md-6 col-xs-12">
              {parse(messages['contactUs.disclaimerDetailsSmall'])}
            </div>
          </div>
          <Button
            linkTo="/"
            className="success-button"
            buttonStyle="redesigned-primary"
            title={messages['contactUs.success.button']}
          />
          <p className="footer-text large">{messages['footer.knowYourRights']}</p>
          <p className="footer-text">{messages['footer.copyright']}</p>
        </div>
      </div>
    </div>
  );
};

export default SuccessScreen;
