import React from 'react';

import Layout from '../layout';
import SEO from '../components/SEO/SEO';
import config from '../../data/SiteConfig';
import useAnalytics from '../hooks/useAnalytics';

import { getLocale, getMessages } from '../locales';
import Privacy from '../components/Privacy';
import PrivacyContentCA from '../components/PrivacyContentCA';
import Button from '../components/common/Button/Button';

function PrivacyPage() {
  const { trackEvent } = useAnalytics();
  trackEvent('View Privacy Policy Page');
  const messages = getMessages();
  const locale = getLocale();
  const privacyContent = Privacy[locale];

  const trackPolicyChange = (index) => {
    index === 1 ? trackEvent('Click Privacy Policy Tab', { Type: 'General' }) : null;
  };

  return (
    <Layout>
      <div className="landing-container">
        <SEO metadata={{ title: config.privacyTitle }} />
        <section className="privacy-policies">
          <div className="container">
            <div className="privacy-content">
              <section className="privacy-header">
                <h1 className="page-title">{messages['privacy.title']}</h1>
              </section>
              <div className="tabs-wrapper">
                {messages['privacy.tabs'].map((tab, index) => (
                  <Button
                    className={index === 0 ? 'active' : ''}
                    linkTo={index === 1 ? '/privacy-policy-ca' : ''}
                    title={tab.name}
                    onClick={() => trackPolicyChange(index)}
                    ariaLabel={tab.name}
                  />
                ))}
              </div>
              <div className="content">{privacyContent}</div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default PrivacyPage;
