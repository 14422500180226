import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import { string, func, bool } from 'prop-types';
import parse from 'html-react-parser';
import Button from '../../common/Button/Button';
import ErrorMessage from '../../common/ErrorMessage/ErrorMessage';
import { createContactUsValidations } from './validations';
import { placeholder, white, inputText } from '../../../styles/base/_variables.scss';
import { getMessages } from '../../../locales';
import { InputAdornment } from '@material-ui/core';
import Tooltip from '../../common/Tooltip/Tooltip';
import HelpIcon from '../../Icons/HelpIcon';

const ContactUsForm = ({ submitForm, status, submitDisabled }) => {
  const messages = getMessages();
  const contactUsValidations = createContactUsValidations(messages);

  const FormTextField = withStyles({
    root: {
      marginTop: '20px',
      marginBottom: '40px',
      '& input': {
        fontSize: 16,
        color: inputText,
        '&::placeholder': {
          fontSize: 16,
          color: placeholder,
          backgroundColor: white,
        },
      },
      '& textarea': {
        fontSize: 16,
        color: inputText,
        '&::placeholder': {
          fontSize: 16,
          color: placeholder,
          backgroundColor: white,
        },
      },
      '& .MuiInput-underline:after, & .MuiInput-underline:before': {
        borderColor: '#E0E5EB',
      },
    },
  })(TextField);

  const {
    handleSubmit,
    control,
    errors: fieldsErrors,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const formatPhoneNumber = (value) => {
    const cleaned = `${value}`.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

    if (match) {
      const intlCode = match[1] ? `(${match[1]}` : '';
      const middle = match[2] ? `) ${match[2]}` : '';
      const last = match[3] ? `-${match[3]}` : '';

      return `${intlCode}${middle}${last}`;
    }

    return value;
  };

  return (
    <form noValidate onSubmit={handleSubmit(submitForm)} className="form">
      <div className="row">
        <div className="col-12 requiredField">
          {messages['contactUs.information']} <span>*</span>
        </div>
        <div className="col-12 col-lg-6">
          <Controller
            name="name"
            as={
              <FormTextField
                inputProps={{ 'aria-label': messages['contactUs.name'] }}
                fullWidth
                name="name"
                id="name"
                autoComplete="family-name"
                placeholder={messages['contactUs.name']}
              />
            }
            control={control}
            rules={{
              required: true,
            }}
          />
        </div>
        <div className="col-12 col-lg-6">
          <Controller
            name="email"
            as={
              <FormTextField
                inputProps={{ 'aria-label': messages['contactUs.email'] }}
                fullWidth
                required
                name="email"
                id="email"
                autoComplete="email"
                placeholder={messages['contactUs.email']}
                error={!!fieldsErrors.email}
                helperText={fieldsErrors?.email?.message}
              />
            }
            control={control}
            rules={contactUsValidations.email}
          />
        </div>
        <div className="col-12 col-lg-6">
          <div>
            <Controller
              name="reference"
              as={
                <FormTextField
                  inputProps={{ 'aria-label': messages['contactUs.reference'] }}
                  fullWidth
                  name="reference"
                  id="reference"
                  placeholder={messages['contactUs.reference']}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          title={
                            <>
                              <p className="title">
                                {parse(messages['contactUs.referenceNumber.tooltip'].title)}
                              </p>
                              <p className="text">
                                {parse(messages['contactUs.referenceNumber.tooltip'].description)}
                              </p>
                            </>
                          }
                          placement="top-end"
                          arrow
                        >
                          <HelpIcon />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              }
              control={control}
            />
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <Controller
            name="phone"
            render={({ onChange, value }) => (
              <FormTextField
                inputProps={{ 'aria-label': messages['contactUs.phone'], maxLength: 14 }}
                fullWidth
                name="phone"
                id="phone"
                autoComplete="tel"
                value={formatPhoneNumber(value || '')}
                placeholder={messages['contactUs.phone']}
                onChange={(e) => onChange(formatPhoneNumber(e.target.value))}
              />
            )}
            control={control}
          />
        </div>
        <div className="col-12 requiredField">
          {messages['contactUs.help']} <span>*</span>
        </div>
        <div className="col-xs col-md-12">
          <Controller
            name="query"
            as={
              <FormTextField
                inputProps={{
                  'aria-label': messages['contactUs.query'],
                }}
                fullWidth
                name="query"
                required
                id="query"
                rows={1}
                placeholder={messages['contactUs.query']}
                multiline
                error={!!fieldsErrors.query}
                helperText={fieldsErrors?.query?.message}
              />
            }
            control={control}
            rules={contactUsValidations.query}
          />
        </div>
        {status === 'ERROR' && (
          <div className="col-xs col-md-12">
            <ErrorMessage message={messages['contactUs.errorMessage']} />
          </div>
        )}
        <div className="col-xs col-md-4">
          <Button
            type="submit"
            buttonStyle="primary"
            size="medium"
            wide
            isDisabled={submitDisabled}
            className="submitButton"
          >
            {messages['contactUs.submit']}
          </Button>
        </div>
      </div>
    </form>
  );
};

ContactUsForm.propTypes = {
  submitForm: func.isRequired,
  status: string,
  submitDisabled: bool,
};

export default ContactUsForm;
