import React from 'react';

export default (
  <ol>
    <li>
      <h2 className="privacy-title">1. PURPOSE</h2>
      <p>
        Spring Oaks Capital, LLC and its affiliates, including Spring Oaks Capital SPV, LLC
        (collectively “Spring Oaks”, “us,” or “we”) is committed to protecting the privacy and the
        security of the information it receives by all employees of Spring Oaks, (collectively,
        "Users") and to protect private information regarding its customers as a top priority.
        Pursuant to the requirements of the Gramm-Leach-Bliley Act (the "GLBA") and guidelines
        established by the Securities Exchange Commission regarding the Privacy of Consumer
        Financial Information (Regulation P) this policy (the “Policy” or the “Regulation P Policy”)
        and the related procedures contained herein are designed to comply with applicable privacy
        laws, including the GLBA and Regulation P, and to protect nonpublic personal information of
        customers. In the event that new privacy-related laws or regulations affecting the
        information practices of Spring Oaks are adopted by federal or state regulators, this Policy
        will be revised as necessary and any changes will be disseminated and explained to all
        personnel.
      </p>
    </li>
    <li>
      <h2 className="privacy-title">2. SCOPE</h2>
      <p>
        This Policy covers the practices of Spring Oaks and applies to all nonpublic personally
        identifiable information, including information contained in customer reports, of our
        current and former customers and the customers with whom we interact. All financial
        companies need to share personal information to run their everyday business. This Policy
        lists the reasons financial companies can share personal information, the reasons Spring
        Oaks chooses to share and whether you can limit this sharing.
      </p>
      <p>
        Spring Oaks’ Website can be found at springoakscapital.com (its “Website”). Spring Oaks’
        Website is not intended for individuals less than the age of eighteen and we do not
        knowingly collect data relating to individuals less than the age of eighteen. Your access to
        and use of the Website is conditional upon your acceptance of and compliance with this
        Privacy Policy. This Privacy Policy applies to everyone, including, but not limited to
        visitors, users, and others, who wish to access or use the Website. By accessing or using
        the Website, you agree to be bound by this Privacy Policy. If you disagree with any part of
        the Privacy Policy, then you do not have our permission to access or use the Website.
      </p>
      <p>
        Nothing in this Privacy Notice is intended to contradict your rights under the Fair Debt
        Collection Practices Act. Spring Oaks will not disclose any information to third parties
        that is otherwise prohibited by the FDCPA.
      </p>
    </li>
    <li>
      <h2 className="privacy-title">3. POLICY</h2>
      <p>
        Spring Oaks and its employees recognize an ongoing commitment to the privacy of its
        customers. All employees will be expected to read, understand, and abide by this policy, as
        well as to follow all related procedures and to uphold the standards of privacy and security
        set forth by Spring Oaks.
      </p>
    </li>
    <li>
      <h2 className="privacy-title">4. PROCEDURES</h2>
      <ol className="mt-0">
        <li>
          <b>4.1</b> In Regulation P, the SEC published guidelines, pursuant to section 501(b) of
          the GLBA, that address the steps a financial institution should take in order to protect
          customer information. The overall security standards that must be upheld are:
          <ol>
            <li>
              {' '}
              <b>4.1.1</b> Ensuring the security and confidentiality of customer records and
              information;
            </li>
            <li>
              <b>4.1.2</b> Protecting against any anticipated threats or hazards to the security or
              integrity of customer records and information; and
            </li>
            <li>
              <b>4.1.3</b> Protecting against unauthorized access to or use of customer records or
              information that could result in substantial harm or inconvenience to any customer.
            </li>
          </ol>
        </li>
        <li>
          <div className="privacy-subtitle">4.2 Responsibility</div>
          <ol>
            <li>
              <b>4.2.1</b> Each Employee has a duty to protect the nonpublic personal information of
              customers collected by us.
            </li>
            <li>
              <b>4.2.2</b> Each Employee has a duty to ensure that nonpublic personal information of
              customers is shared only with employees and others in a way that is consistent with
              Spring Oak’s Privacy Notice and the procedures contained in this Policy.
            </li>
            <li>
              <b>4.2.3</b> Each Employee has a duty to ensure that access to nonpublic personal
              information of customers is limited as provided in the Privacy Notice and this Policy.
            </li>
            <li>
              <b>4.2.4</b> No Employee is authorized to sell, on behalf of Spring Oaks or otherwise,
              nonpublic information of customers or SMS opt-in permissions from customers.
            </li>
            <li>
              <b>4.2.5</b> Employees with questions concerning the collection and sharing of, or
              access to, nonpublic personal information of customers must look to Spring Oaks CCO
              for guidance.
            </li>
            <li>
              <b>4.2.6</b> Violations of these policies and procedures will be addressed in a manner
              consistent with other disciplinary guidelines.
            </li>
          </ol>
        </li>
        <li>
          <div className="privacy-subtitle">4.3 Information Practices</div>
          <ol>
            <li>
              <b>4.3.1</b> Spring Oaks limits the use, collection, and retention of customer
              information to what we believe is necessary or useful to conduct our business or to
              offer quality products and services. Spring Oaks collects nonpublic personal
              information about customers from various sources. These sources and examples of types
              of information collected include:
              <ol>
                <li>
                  <b>4.3.1.1</b> Name, address, telephone number, social security number or taxpayer
                  ID number, date of birth, employment status, annual income, and net worth;
                </li>
                <li>
                  <b>4.3.1.2</b> Information about transactions with us and account custodian(s),
                  such as account balance, types of transactions, parties to the transactions.
                </li>
                <li>
                  <b>4.3.1.3</b> Information received from customer reporting agencies, such as
                  credit reports, prior owners of the account, government agencies and other
                  companies.
                </li>
              </ol>
            </li>
          </ol>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>
                    <b>Reasons we can share your personal information</b>
                  </th>
                  <th>
                    <b>Does Spring Oaks share?</b>
                  </th>
                  <th>
                    <b>Can you limit this sharing?</b>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <b>For our everyday business purposes</b> – such as to process your
                    transactions, maintains your account(s), respond to court orders and legal
                    investigations or report to credit bureaus
                  </td>
                  <td>Yes </td>
                  <td>No </td>
                </tr>
                <tr>
                  <td>
                    <b>For our marketing purposes</b> – to offer our products and services to you
                  </td>
                  <td>No </td>
                  <td>No </td>
                </tr>
                <tr>
                  <td>
                    <b>For joint marketing with other financial companies</b>{' '}
                  </td>
                  <td>No </td>
                  <td>We do not share </td>
                </tr>
                <tr>
                  <td>
                    <b>For our affiliates’ everyday business purposes</b> – such as information
                    about your transactions and experiences
                  </td>
                  <td>Yes </td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>
                    <b>For our affiliates’ everyday business purposes</b> – such as information
                    about your creditworthiness
                  </td>
                  <td>Yes</td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>
                    <b>For nonaffiliates to market to you</b>
                  </td>
                  <td>No</td>
                  <td>We do not share</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p>
            <b>Definitions:</b>
            <br />
            <br />
            <b>
              <u>Affiliates</u>
            </b>{' '}
            are companies related by common ownership or control. They can be financial and
            non-financial companies.
            <br />
            <br />
            <b>
              <u>Nonaffiliates</u>
            </b>{' '}
            are companies not related by common ownership or control. They can be financial and
            non-financial companies.
            <br />
            <br />
            <b>
              <u>Joint marketing</u>
            </b>{' '}
            is a formal agreement between nonaffiliated financial companies that together market
            financial products or services to you. Spring Oaks does not jointly market.
          </p>
        </li>
        <li>
          <div className="privacy-subtitle">
            4.4 Disclosure of Information to Nonaffiliated Third Parties
          </div>
          Spring Oaks does not disclose nonpublic personal information to nonaffiliated third
          parties, except under one of the GLBA privacy exceptions, as described below.
          <ol>
            <li>
              <b>4.4.1</b> Types of Permitted Disclosures – The Exceptions
              <ol>
                <li>
                  <b>4.4.1.1</b> In certain circumstances, Regulation P permits Spring Oaks to share
                  nonpublic personal information about its customers with nonaffiliated third
                  parties without providing an opportunity for those individuals to opt out. These
                  circumstances include sharing information with a nonaffiliated entity:
                  <ol>
                    <li>
                      <b>4.4.1.1.1</b> As necessary to effect, administer, or enforce a transaction
                      that a client requests or authorizes;
                    </li>
                    <br />
                    <li>
                      <b>4.4.1.1.2</b> In connection with processing or servicing a collection
                      account or a service a client authorizes; and
                    </li>
                    <br />
                    <li>
                      <b>4.4.1.1.3</b> In connection with maintaining or servicing a collection
                      account with Spring Oaks.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <div className="privacy-subtitle">4.5 Service Providers</div>
          <ol>
            <li>
              <b>4.5.1</b> From time to time, we may have relationships with nonaffiliated third
              parties (such as attorneys, auditors, custodians, and other consultants), who, in the
              ordinary course of providing their services to us, may require access to information
              containing nonpublic information. These third-party service providers are necessary
              for us to provide our collection services.
            </li>
            <br />
            <li>
              <b>4.5.2</b> When we are not comfortable that service providers (e.g., attorneys,
              auditors, and other financial institutions) are already bound by duties of
              confidentiality, we require assurances from those service providers that they will
              maintain the confidentiality of nonpublic information they obtain from or through us.
            </li>
            <br />
            <li>
              <b>4.5.3</b> In addition, we select and retain service providers that we believe are
              capable of maintaining appropriate safeguards for nonpublic information, and we will
              require contractual agreements from our service providers that they will implement and
              maintain such safeguards.
            </li>
          </ol>
        </li>
        <li>
          <div className="privacy-subtitle">4.6 Processing and Servicing</div>
          <ol>
            <li>
              <b>4.6.1</b> We may also share information when it is necessary to effect, administer,
              or enforce a transaction requested or authorized by our customers.
              <ol>
                <li>
                  <b>4.6.1.1</b> In this context, "necessary to effect, administer, or enforce a
                  transaction": includes what is required or is a usual, appropriate, or acceptable
                  method to carry out the transaction or service of which the transaction is a part,
                  and record, service, or maintain the customer’s account in the ordinary course of
                  providing collection services
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <div className="privacy-subtitle">4.7 Sharing as Permitted or Required by Law</div>
          <ol>
            <li>
              <b>4.7.1</b> Spring Oaks may disclose information to nonaffiliated third parties as
              required or allowed by law.
              <ol>
                <li>
                  <b>4.7.1.1</b> For example, this may include disclosures in connection with a
                  subpoena or similar legal process, a fraud investigation, an audit or examination.
                </li>
              </ol>
            </li>
            <br />
            <li>
              <b>4.7.2</b> By understanding how we share data with our clients, their agents,
              service providers, parties related to transactions in the ordinary course of business,
              or joint marketers, we endeavor to ensure that client data is shared only within the
              exceptions noted above.
            </li>
          </ol>
        </li>
        <li>
          <div className="privacy-subtitle">4.8 Privacy Notice</div>
          <ol>
            <li>
              <b>4.8.1</b> Spring Oaks will send a Privacy Notice under Regulation P on accounts it
              owns consistent with this Policy.
            </li>
          </ol>
        </li>
      </ol>
    </li>
    <li>
      <h2 className="privacy-title">5. INFORMATION WE COLLECT ABOUT YOU</h2>
      <ol>
        <li>
          <div className="privacy-subtitle">5.1 Personal Information</div>
          <ol>
            <li>
              <b>5.1.1</b> We may collect and use the following personal information that
              identifies, relates to, describes, references, is capable of being associated with, or
              could reasonably be linked, directly or indirectly, with a customer, device, or
              household (“personal information”). Inclusion of a category indicates that we may
              collect some information in that category. It does not mean that we collect all
              information listed in that category in all situations. We may have collected the
              following categories of personal information from customers within the last 12 months:
            </li>
            <div className="table-container">
              <table>
                <thead>
                  <th>
                    <b>Categories of Personal Information</b>
                  </th>
                  <th>
                    <b>Examples of Specific Types of Personal Information Collected</b>
                  </th>
                  <th>
                    <b>Collected</b>
                  </th>
                  <th>
                    <b>Purpose</b>
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td>Identifiers</td>
                    <td>
                      A real name, alias, postal address, email address, telephone numbers, Internet
                      Protocol address, account number, Social Security number, date of birth, or
                      other similar identifiers.
                    </td>
                    <td>YES</td>
                    <td>
                      (a) Necessary to provide the services <br />
                      (b) Necessary to comply with a legal obligation <br />
                      (c) Necessary for our legitimate business interests <br />
                      (d) Prevent fraud
                    </td>
                  </tr>
                  <tr>
                    <td>Personal Information categories</td>
                    <td>
                      A name, signature, Social Security number, physical characteristics or
                      description, address, telephone number, passport number, driver's license or
                      state identification card number, education, employment, employment history,
                      bank account number, credit card number, debit card number, or any other
                      financial information. Some personal information included in this category may
                      overlap with other categories.
                    </td>
                    <td>YES</td>
                    <td>
                      (a) Necessary to provide the services <br />
                      (b) Necessary to comply with a legal obligation <br />
                      (c) Necessary for our legitimate business interests <br />
                      (d) Prevent fraud
                    </td>
                  </tr>
                  <tr>
                    <td>Protected classification characteristics</td>
                    <td>Age (40 years or older), veteran or military status,</td>
                    <td>YES</td>
                    <td>
                      (a) Necessary to provide the services <br />
                      (b) Necessary to comply with a legal obligation <br />
                      (c) Necessary for our legitimate business interests <br />
                    </td>
                  </tr>

                  <tr>
                    <td>Commercial Information</td>
                    <td>
                      Records of personal property, products or services purchased, obtained, or
                      considered, or other purchasing or consuming histories or tendencies
                    </td>
                    <td>YES</td>
                    <td>
                      (a) Necessary to provide the services <br />
                      (b) Necessary to comply with a legal obligation <br />
                      (c) Necessary for our legitimate business interests <br />
                    </td>
                  </tr>
                  <tr>
                    <td>Professional or employment- related information</td>
                    <td>Current or past job history.</td>
                    <td>YES</td>
                    <td>
                      (a) Necessary to provide the services <br />
                      (b) Necessary to comply with a legal obligation <br />
                      (c) Necessary for our legitimate business interests <br />
                    </td>
                  </tr>
                  <tr>
                    <td>Sensory data</td>
                    <td>Audio, electronic, visual.</td>
                    <td>YES</td>
                    <td>
                      (a) Necessary to provide the services <br />
                      (b) Necessary to comply with a legal obligation <br />
                      (c) Necessary for our legitimate business interests <br />
                    </td>
                  </tr>
                  <tr>
                    <td>Sensitive Personal Information</td>
                    <td>
                      Government-issued identifying numbers, such as a driver’s license, passport or
                      social security number, financial account details that allow access to an
                      account, such as a credit card number and access code, contents of mail,
                      email, chat, or text messages.
                    </td>
                    <td>
                      YES <br />
                      <br />
                      Sensitive Personal Information is not collected or processed with the purpose
                      of inferring characteristics about a customer.
                    </td>
                    <td>
                      (a) Necessary to provide the services <br />
                      (b) Maintain and service your account <br />
                      (c) Process payments <br />
                      (d) ensure security and integrity regarding the use of such personal
                      information <br />
                      (e) verify or maintain the safety and quality of the services <br />
                      (f) Necessary to comply with a legal obligation <br />
                      (g) Necessary for our legitimate business interests
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <li>
              <b>5.1.2</b> Personal Information does not include:
              <ul>
                <li>Publicly available information from government records.</li>
                <li>Deidentified or aggregated customer information.</li>
                <li>Excludes types of information, like:</li>
                <li>
                  Health or medical information covered by the Health Insurance Portability and
                  Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical
                  Information Act (CMIA) or clinical trial data;
                </li>
                <li>
                  Personal information covered by certain sector-specific privacy laws, including
                  the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or
                  California Financial Information Privacy Act (FIPA), and the Driver's Privacy
                  Protection Act of 1994.
                </li>
              </ul>
            </li>
          </ol>
        </li>
        <li>
          <div className="privacy-subtitle">5.2 How We Collect Your Information</div>
          <ol>
            <li>
              <b>5.2.1</b> We collect most of this personal information directly from sellers of
              portfolios of debt, as well as from you by telephone, written correspondence through
              the mail, email, SMS, chat or fax, by viewing public social media/network pages, or
              other information available online. However, we may also collect information:
            </li>
            <ul>
              <li>From publicly accessible sources (e.g., property records or court records);</li>
              <li>From your transactions with us;</li>
              <li>
                From our service providers (e.g., servicing vendors, letter vendor, location
                vendors, payment processing vendors, call analytics vendor, and/or electronic
                signature service provider);
              </li>
              <li>
                Directly from a third party (e.g., third parties contacted during location
                activities pursuant to 15 U.S.C. §1692b, such as your friends, neighbors, relatives,
                and/or employer);
              </li>
              <li>Customer reporting agencies (CRAs)</li>
              <li>
                From a third party with your consent (e.g., your authorized representative and/or
                attorney); and
              </li>
              <li>From activity on our website.</li>
            </ul>
          </ol>
        </li>

        <li>
          <div className="privacy-subtitle">5.3 How We Use Your Information</div>
          <ol>
            <li>
              <b>5.3.1</b> Personal information is collected solely for the purpose of debt recovery
              in a lawful manner and remains part of our records until we determine the information
              is no longer needed, or we are required by law to delete such information. We will
              collect the minimum amount of data necessary to collect a debt.
            </li>
            <br />
            <li>
              <b>5.3.2</b> We may use your personal information for one or more of the following
              business purposes:
            </li>
            <ul>
              <li>
                To fulfill or meet the reason you provided the information. For example, if you
                share your personal information to make a payment, we will use that information to
                process your payment.
              </li>
              <li>
                Performing services on behalf of the business or service provider, including
                maintaining or servicing records, providing customer service, processing or
                fulfilling orders and transactions, verifying customer information, processing
                payments, or providing similar services on behalf of the business or service
                provider;
              </li>
              <li>To provide you with information or services that you request from us;</li>
              <li>
                To respond to law enforcement requests and as otherwise permitted by applicable law,
                court order, or governmental regulations;
              </li>
              <li>
                To Ensure security and integrity to the extent personal information is reasonably
                necessary and proportionate to these purposes;
              </li>
              <li>
                To verify or maintain the quality or safety of the services or account, or to
                prevent fraud.
              </li>
            </ul>
            <br />
            <li>
              <b>5.3.3</b> Sensitive personal information is collected only as necessary to perform
              our services, process payments, maintain or service your account, to ensure security
              and integrity regarding the use of such personal information, and to verify or
              maintain the safety and quality of the Services. Sensitive personal information is not
              collected with the purpose of inferring characteristics about you. We do not collect
              “sensitive” personal information as that term is defined in certain states.
              <br />
              <br />
              We will not collect additional categories of personal information or use the personal
              information we collected for materially different, unrelated, or incompatible purposes
              without providing you notice.
            </li>
            <br />
            <li>
              <b>5.3.4</b> We do not sell and will not sell your personal information including SMS
              opt-in permission. We also do not "share" your SMS opt-in permission nor your personal
              information, as that term is defined in the California Privacy Rights Act.
            </li>
          </ol>
        </li>
        <li>
          <div className="privacy-subtitle">5.4 Who We Share Your Personal Information With</div>
          <ol>
            <li>
              <b>5.4.1</b> We may share personally identifiable information it collects with our
              employees and affiliates who need to know that information to service your account.
              Except as provided below, we do not share or disclose any personally identifiable
              information to any company or marketing group external to us. We may share your
              personal information with third parties and service providers to the extent it is
              reasonably necessary to manage or service your account, verify employment, determine
              location, process payment, fulfill a transaction, provide customer service, or as
              otherwise authorized by law.
            </li>
            <br />
            <li>
              <b>5.4.2</b> Further, we may disclose personally identifiable information (i) to
              another entity with which we enter or reasonably may enter into a corporate
              transaction, such as, for example, a merger, consolidation, acquisition, or asset
              purchase, (ii) to a third party pursuant to a subpoena, court order, or other form of
              legal process or in response to a request by or on behalf of any local, state,
              federal, or other government agency, department, or body, whether or not pursuant to a
              subpoena, court order, or other form of legal process, or in connection with
              litigation brought against, or on behalf of, Spring Oaks, where appropriate, (iii) to
              a third party if determined by Spring Oaks in its sole judgment that such disclosure
              is appropriate to protect the life, health, or property of Spring Oaks or any other
              person or entity, all in compliance with applicable law, (iv) to third parties as
              authorized or designated by you, or (v) to conduct any other legitimate business
              activity not otherwise prohibited by law. The foregoing is not intended to obviate or
              displace any legal obligations or duties applicable to Spring Oaks.
            </li>
            <br />
            <li>
              <b>5.4.3</b> Except as necessary for us to provide the services, information, or
              products requested by a website user, or except for the disclosures identified in the
              preceding paragraphs, the user may opt out of having his or her personally
              identifiable information, which has been voluntarily provided to us through or from
              its website, prospectively retained by us, used by us for secondary purposes, or
              disclosed by us to third parties.
            </li>
            <br />
            <li>
              <b>5.4.4</b> E-mail posted or sent to us may not be secure against interception by
              unauthorized individuals. To protect against interception by unauthorized individuals,
              or because we can not verify your identity, we may be unable to respond to e-mail
              requests concerning accounts placed for collection unless you have requested or
              authorized us to do so.
            </li>
          </ol>
        </li>
        <li>
          <div className="privacy-subtitle">
            5.5 Sharing your information with Customer Reporting Agencies
          </div>
          <ol>
            <li>
              <b>5.5.1</b> Customer Reporting Agencies (CRAs) collect and maintain information on
              customer and business credit profiles on behalf of organizations in the United States.
              We may share information about you with CRAs and may carry out periodic searches with
              them to verify your identity or manage your account.
            </li>
            <br />
            <li>
              <b>5.5.2</b> Details of your account(s) with us may be sent to CRAs and recorded by
              them. This information may be supplied by CRAs and may be used and searched by us and
              other organizations, such as debt collection agencies, in order to:
              <ul>
                <li>consider applications for credit and credit related services;</li>
                <li>locate debtors and recover debts; and</li>
                <li>manage your accounts.</li>
              </ul>
            </li>
            <br />
            <li>
              <b>5.5.3</b> Spring Oaks may furnish account information to Experian, Equifax, and
              Trans Union. You have a right to obtain an annual copy of your credit file from CRAs
              by visiting www.annualcreditreport.com.
            </li>
          </ol>
        </li>
        <li>
          <div className="privacy-subtitle">5.6 Sale of Personal Information</div>
          <p>
            We do not sell and will not sell your personal information, including SMS opt-in
            permission. We also do not "share" your SMS opt-in permission nor your personal
            information as that term is defined in the California Privacy Rights Act.
          </p>
        </li>
        <li>
          <div className="privacy-subtitle">5.7 How We Use Cookies</div>
          <p>
            Spring Oaks may collect certain computer and browser information through automated
            technologies such as cookies and web beacons when you visit our website. Cookies are
            small pieces of (text) information that are sent to your browser when you visit the
            website to identify the browser or to store information or settings in the browser. They
            are then stored on the hard disk or in the memory of your equipment. The browser can
            send this information back on your next visit to the website. The cookies placed via the
            website cannot damage your equipment or the files stored on it. Web beacons (also known
            as internet tags, pixel tags, or clear GIFs) link web pages to web servers and their
            cookies may be used to transmit information collected back to a web server.
          </p>
        </li>
        <li>
          <div className="privacy-subtitle">5.8 Your Choices</div>
          <p>
            Spring Oaks offers you certain choices in connection with the personal data it collects
            from you, including:
          </p>
          <p>
            Your Spring Oaks Account: You may review, update, and correct your contact information
            by doing one of the following:
          </p>
          <ul>
            <li>Calling us, toll-free, at 866-281-3065; or</li>
            <li>Email us at springoakscapital.com; or</li>
            <li>Write to us at P.O. Box 1216, Chesapeake, VA 23327-1216.</li>
          </ul>
          <p>
            Your Choice to Decline Cookies: You may adjust your browser settings to decline cookies
            if you do not want to accept Spring Oaks cookies. However, declining cookies may affect
            proper operation of Spring Oaks’s website.
          </p>
          <p>
            Your Payments: You can elect not to utilize the payment portal of Spring Oaks’s website
            and make payments via credit card or check.
          </p>
          <p>
            If you have any questions regarding any of these choices, please contact Spring Oaks.
          </p>
        </li>
        <li>
          <div className="privacy-subtitle">5.9 How Long We Keep Your Information</div>
          <p>
            Spring Oaks will retain your personal data until we determine the information is no
            longer needed, or as otherwise required by law.
          </p>
        </li>
        <li>
          <div className="privacy-subtitle">5.10 Your Options:</div>
          <ol>
            <li>
              <b>5.10.1</b> Request to access personal information – You may submit a verifiable
              request for access to your personal information that we collected about you, subject
              to certain exceptions. This may include the categories and specific pieces of
              information collected, the sources of that information and the business or commercial
              purpose for the collection or disclosure of that information.
            </li>
            <br />
            <li>
              <b>5.10.2</b> Request correction of personal data we hold about you. This enables you
              to have any incomplete or inaccurate data we hold about you corrected, though we may
              need to verify the accuracy of the new data you provide to us.
            </li>
            <br />
            <li>
              <b>5.10.3</b> Request deletion of personal information –You may request that we delete
              your personal information, however, state and federal law may prohibit us from
              deleting personal information, which Spring Oaks will disclose to you. You may also
              request to ask us to delete or remove your personal data where you have successfully
              exercised your right to object to processing (see below), where we may have processed
              your information unlawfully or where we are required to erase your personal data to
              comply with local law. We may deny your deletion request if retaining the information
              is necessary for us or our service providers to:
              <ul>
                <li>
                  Complete the transaction for which the personal information was collected, provide
                  a good or service requested by you, or reasonably anticipated within the context
                  of our ongoing business relationship with you, or otherwise perform a contract
                  between you and us.
                </li>
                <li>
                  Detect security incidents, protect against malicious, deceptive, fraudulent, or
                  illegal activity; or prosecute those responsible for that activity.
                </li>
                <li>
                  Debug to identify and repair errors that impair existing intended functionality.
                </li>
                <li>
                  Enable solely internal uses that are reasonably aligned with your expectations
                  based on your relationship with us.
                </li>
                <li>Comply with an existing legal obligation.</li>
                <li>
                  Otherwise use your personal information, internally, in a lawful manner that is
                  compatible with the context in which you provided the information.
                </li>
              </ul>
            </li>
            <br />
            <li>
              <b>5.10.4</b> Object to processing of your personal data – Where we are relying on a
              legitimate interest (or those of a third party) and there is something about your
              particular situation which makes you want to object to processing on this ground as
              you feel it impacts on your fundamental rights and freedoms, you may object to
              processing of such data. You also may to object where we are processing your personal
              data for direct marketing purposes. In some cases, we may demonstrate that we have
              compelling legitimate grounds to process your information which override your rights
              and freedoms.
            </li>
            <br />
            <li>
              <b>5.10.5</b> Request restriction of processing of your personal data - This enables
              you to ask us to suspend the processing of your personal data in the following
              scenarios: (a) if you want us to establish the data's accuracy; (b) where our use of
              the data is unlawful but you do not want us to erase it; (c) where you need us to hold
              the data even if we no longer require it as you need it to establish, exercise or
              defend legal claims; or (d) you have objected to our use of your data but we need to
              verify whether we have overriding legitimate grounds to use it.
            </li>
            <br />
            <li>
              <b>5.10.6</b> Request the transfer of your personal data to you or to a third party -
              We will provide to you, or a third party you have chosen, your personal data in a
              structured, commonly used, machine-readable format. Note that this only applies to
              automated information which you initially provided consent for us to use or where we
              used the information to perform a contract with you.
            </li>
            <br />
            <li>
              <b>5.10.7</b> Withdraw consent at any time where we are relying on consent to process
              your personal data – however, this will not affect the lawfulness of any processing
              carried out before you withdraw your consent. If you withdraw your consent, we may not
              be able to provide certain products or services to you. We will advise you if this is
              the case at the time you withdraw your consent.
            </li>
            <br />
            <li>
              <b>5.10.8</b> Automated decision making and profiling - 'Automated Decision Making'
              refers to credit decisions regarding customer lending terms or conditions predicated
              solely on the creditor’s automated processing of personal data. This means credit
              decisions may be made by a creditor, for example, using software code or an algorithm
              which does not require human intervention. We do not extend credit or make credit
              decisions for any person and we do not use your personal data as part of any such
              automated decision making or profiling when servicing your account.
            </li>
            <br />
            <li>
              <b>5.10.9</b> Non-discrimination - We will not discriminate against you if you
              exercise any of these options.
            </li>
            <br />
            <li>
              <b>5.10.10</b> Authorized agent – You can designate an authorized agent to make any of
              these requests by providing your express written authorization. We must be able to
              verify your identity and the authorization must include the authorized agent’s name,
              address, telephone number, and email address (for providing the personal information
              collected or to respond to a request for deletion).
            </li>
            <br />
            <li>
              <b>5.10.11</b> If you wish to exercise any of these options, please contact Spring
              Oaks by doing one of the following:
              <ul>
                <li>Calling us, toll-free, at 866-281-3065; or</li>
                <li>Email us at springoakscapital.com; or</li>
                <li>Write to us at P.O. Box 1216, Chesapeake, VA 23327-1216.</li>
              </ul>
              <p>
                If you choose to contact directly by telephone or in writing, you will need to
                provide us with:
              </p>
              <ul>
                <li>
                  Enough information to identify you [e.g., your full name, address and customer or
                  matter reference number];
                </li>
                <li>
                  Proof of your identity and address (e.g., a copy of your driving license or
                  passport and a recent utility or credit card bill); and
                </li>
                <li>
                  Describe your request with sufficient detail that allows us to properly
                  understand, evaluate and respond to it.
                </li>
              </ul>
            </li>
            <br />
            <li>
              <b>5.10.12</b> We are not obligated to make a data access or data portability
              disclosure if we cannot verify that the person making the request is the person about
              whom we collected information, or is someone authorized to act on such person’s
              behalf. To prevent anyone other than you, or your authorized agent, from exercising
              the right options to know or the right to delete with respect to your personal
              information, we follow procedures to verify your, or your agent’s, identity. These
              procedures seek to confirm that the person making a request is the person about whom
              we have collected personal information or that person’s authorized agent. The
              verification procedures involve matching data points that you provide with your
              request against information about you we already have in our records and that we have
              determined to be reliable for purposes of verifying your identity. We will use
              information you provide in your completed request form to verify your identity, and we
              may request additional information if necessary to complete the verification process.
              <br />
              <br />
              You will not have to pay a fee to access your personal data (or to exercise any of the
              other options). However, we may charge a reasonable fee if your request is clearly
              unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your
              request in these circumstances.
              <br />
              <br />
              If you would like to appeal of our decision with respect to your exercise of the above
              options, an appeal may be sent to the data controller using the same process for
              submitting initial requests. A response to an appeal may take up to 60 days, at which
              time Spring Oaks shall respond to your request.
            </li>
          </ol>
        </li>
        <br />
        <li>
          <b>5.11</b> Response Timing and Format
          <p>
            We endeavor to respond to a verifiable customer request within 45 days of its receipt.
            If we require more time (up to 90 days), we will inform you of the reason and extension
            period in writing. If you have an account with us, we will deliver our written response
            to that account. If you do not have an account with us, we will deliver our written
            response by mail or electronically, at your option. Any disclosures we provide will only
            cover the 12-month period preceding the verifiable customer request's receipt. The
            response we provide will also explain the reasons we cannot comply with a request, if
            applicable. For data portability requests, we will select a format to provide your
            personal information that is readily useable and should allow you to transmit the
            information from one entity to another entity without hindrance.
          </p>
        </li>
      </ol>
    </li>
    <li>
      <h2 className="privacy-title">6. HOW WE PROTECT INFORMATION</h2>
      <p>
        Spring Oaks has implemented physical, electronic, and procedural security safeguards to
        protect against the unauthorized release of or access to personal information. We employ
        internal and external system safeguards designed to protect confidentiality and security of
        personal information. The confidentiality of any communication or material transmitted to or
        from Spring Oaks via the website or via e-mail cannot be, and is not, guaranteed. You
        acknowledge that the technical processing and transmission of the website’s content may be
        transferred unencrypted and involve: (a) transmissions over various networks; and (b)
        changes to confirm and adapt to technical requirements of connecting networks or devices. If
        any questions arise about security, please contact us using the information provided above.
      </p>
    </li>
    <li>
      <h2 className="privacy-title">7. LINKS TO OTHER WEBSITES</h2>
      <p>
        Our website may contain links to enable you to visit other websites of interest easily.
        However, once you have used these links to leave our site, you should note that we do not
        have any control over that other website. Therefore, we cannot be responsible for the
        protection and privacy of any information which you provide while visiting such sites and
        such sites are not governed by this privacy statement. You should exercise caution and look
        at the privacy statement applicable to the website in question.
      </p>
    </li>
    <li>
      <h2 className="privacy-title">8. PRIVACY NOTICE CHANGES</h2>
      <p>
        Spring Oaks may change this Privacy Notice at any time. Notice of any new or revised Privacy
        Notice, as well as the location of the new or revised statement, will be posted on the
        website after the change. It is the obligation of users visiting the website before the
        change to learn of changes to the Privacy Notice since their last visit.
        <br />
        <br />
        If there are material changes to the privacy statement or in how we will use your personal
        data, we will notify you by prominently posting a notice of such changes before they take
        effect or by directly sending you a notification.
      </p>
    </li>
    <li>
      <h2 className="privacy-title">9. AUDITING PROCEDURES</h2>
      <p>
        Spring Oaks will periodically monitor electronic systems such as e-mail and Internet. Any
        direct, indirect, or attempted violation of this Policy, by or on behalf of a User, and any
        actual or attempted violation by a Third Party on behalf of a User, shall be considered a
        violation of the Policy by the User, and the User shall be held directly accountable. In the
        event we become aware that any User activity may have violated this Policy and/or exposed us
        to civil or criminal liability, Spring Oaks reserves the right to investigate such activity;
        monitor, collect evidence, and block access to such material; and cooperate with legal
        authorities and Third Parties in investigating any alleged violations of this Policy. We
        also reserve the right to implement technical mechanisms to prevent policy violations
        including electronic monitoring of systems such as e-mail and Internet. Users who violate
        this Policy or any other Spring Oaks-published policies or standards may be subject to
        disciplinary action by us, up to and including immediate termination from employment. In
        addition, conduct that is unlawful under applicable laws may subject Users to civil and, in
        some cases, criminal prosecution.
      </p>
    </li>

    <li>
      <h2 className="privacy-title">10. FREQUENCY OF TRAINING AND TESTING</h2>
      <p>
        Spring Oaks is the controller and responsible for your personal data. All Spring Oaks
        personnel will receive training on our company policies applicable to their position upon
        hire or promotion. The same company personnel will complete policy attestations on an annual
        basis.
        <br />
        <br />
        Questions or comments about our data practices can be submitted to:
        <br />
        <br />
        Spring Oaks Capital, LLC
        <br />
        P.O. Box 1216
        <br />
        Chesapeake, VA 23327-1216
        <br />
        <br />
        Or by calling us, toll-free, at 866-281-3065.
      </p>
    </li>
  </ol>
);
